
.background-text {
    font-family: 'Dancing Script';
}

.rubik-text {
    font-family: 'Rubik';
}

.landing {
    width: 100%;
    min-height: 100vh;   
}

.landing .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing .logo-wrapper img {
    height: 80px;
    width: auto;
}

.landing .row {
    padding: 40px 20px;
}

.header-title {
    color: #07486F;
    font-weight: bold;
    font-size: 20px;
    margin: 0;
}

.header-title-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 20px;
}

.background-text {
    position: absolute;
    font-size: 25vw;
    top: -160px;
    opacity: 0.1;
    z-index: -1;
    
}

@media (max-width: 1200px){
    .background-text {
        top: -110px;
    }
}

@media (max-width: 900px){
    .background-text {
        top: -40px;
    }
}

@media (max-width: 768px){
    .background-text {
        top: 25px !important;
    }
}

@media (max-width: 632px){
    .background-text {
        top: 60px !important;
    }
}

@media (max-width: 485px){
    .background-text {
        top: 120px !important;
    }
}

@media (max-width: 315px){
    .background-text {
        top: 150px !important;
    }
}

.main-content img {
    width: 100%;
    height: auto;
}

.main-content .text-wrapper {
    padding: 0 20px;
    margin: 20px 0;
}

.main-content .text-wrapper h2 {
    font-size: 50px;
    font-weight: bold;
}

.main-content .description-title {
    margin: 20px 0;
}

.title-button {
    background: #07486F;
    color: white;
    padding: 12px 110px;
    border-radius: 40px;
    border: none;
}

.row-text {
    margin: 50px 0;
}

.row-text .inner-title {
    font-size: 45px;
    font-weight: bold;
    margin: 10px 0;
}

@media (max-width: 380px){
    .main-content .text-wrapper h2 {
        font-size: 35px !important;
    }

    .title-button {
        width: 100%;
        padding: 12px 0;
    }

    .row-text .inner-title {
        font-size: 30px;
    }
}

.row-text .inner-wrapper {
    padding: 0 15px;
}

.row-text .inner-description {
    margin: 20px 0;
}

.row-text .inner-quote {
    border-left: solid 4px #07486F;
    padding-left: 25px;
}

.row-text .inner-quote-text {
    font-weight: bold;
    font-style: italic;
    font-size: 15px;
}

.row-text .inner-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-text .inner-image-wrapper img {
    height: 25vw;
    width: auto;
    margin: 10px;
}

@media (max-width: 700px){
    .row-text .inner-image-wrapper {
        flex-direction: column;
    }

    .row-text .inner-image-wrapper img{
        width: 100%;
        max-width: 300px;
        height: auto;
    }
}

.row-text .inner-side-image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}

.row-text .inner-side-image-wrapper img {
    width: auto;
    height: auto;
    max-height: 400px; 
}

.row-text .intext-img, .row-text .intext-logo-img {
    width: 100%;
    display: flex;
    justify-content: center;
}

.row-text .intext-img img {
    width: 90%;
    height: auto;
    max-width: 400px;
}

.intext-logo-img img {
    max-width: 80px;
}

.social-media-wrapper {
    width: 100%;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.social-media-wrapper svg {
    width: 20px;
    height: 20px;
    margin: 15px 25px;
    cursor: pointer;
    fill: black;
}

.inner-video-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}