@font-face {
    font-family: 'Rubik';
    font-weight: normal;
    font-style: normal;
    src: local('Rubik'), url('Rubik/Rubik-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Rubik';
    font-weight: normal;
    font-style: italic;
    src: local('Rubik'), url('Rubik/Rubik-Italic-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Dancing Script';
    font-weight: normal;
    font-style: normal;
    src: local('Dancing Script'), url('Dancing_Script/DancingScript-VariableFont_wght.ttf');
}